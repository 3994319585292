<!-- eslint-disable -->
<template>
  <b-card-actions
    title="Install Script"
    action-collapse
    class="user-custom-card"
  >
    <b-card-text>
      <b-row>
        <b-col
          cols="6"
          class="mobileScrn"
        >
          <b-form-group
            label="Default Site"
            label-for="defaulSite"
          >
            <b-form-select
              id="defaulSite"
              v-model="formData.defaultSite"
              :options="siteOptions"
              class="mobileScrnInput"
            />
          </b-form-group>
          <b-form-group
            label="IPv4"
            label-for="ipv4"
          >
            <b-form-input
              id="ipv4"
              v-model="formData.IPv4"
              class="mobileScrnInput"
            />
          </b-form-group>
          <b-form-group
            label="IPv6"
            label-for="ipv6"
          >
            <b-form-input
              id="ipv6"
              v-model="formData.IPv6"
              class="mobileScrnInput"
            />
          </b-form-group>
          <b-form-group
            label="Network Name"
            label-for="networkName"
          >
            <b-form-input
              id="networkName"
              v-model="formData.networkName"
              class="mobileScrnInput"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          class="mobileScrn"
        >
          <b-form-group
            label="DB Password"
            label-for="dbPass"
          >
            <b-form-input
              id="dbPass"
              v-model="formData.dbPass"
              class="mobileScrnInput"
            />
          </b-form-group>
          <b-form-group
            label="App Secret"
            label-for="appSecret"
          >
            <b-form-input
              id="appSecret"
              v-model="formData.appSecret"
              class="mobileScrnInput"
            />
          </b-form-group>
          <b-form-group
            label="App Prefix"
            label-for="appPrefix"
          >
            <b-form-input
              id="appPrefix"
              v-model="formData.appPrefix"
              class="mobileScrnInput"
            />
          </b-form-group>
          <b-button
            class="mt-3 mobileScrnInput"
            variant="primary"
            block
            @click="saveScriptSettings"
          >
            <span class="align-middle">Generate Script</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-text>
    <b-modal
      id="install-script-modal"
      modal-class="modal-primary"
      centered
      hide-footer
      hide-header
    >
      <b-card-text>
        <prism-editor
          v-model="code"
          class="my-editor"
          :highlight="highlighter"
          :line-numbers="true"
        />
      </b-card-text>
    </b-modal>
  </b-card-actions>
</template>

<script>
/* eslint-disable */
import {
  BButton,
  BCardText, BCol,
  BFormGroup, BFormInput, BRow, BModal, BFormSelect,
} from 'bootstrap-vue'
import BCardActions from '@/layouts/components/UserDetailCard.vue'
import bcrypt from 'bcryptjs'
import { PrismEditor } from 'vue-prism-editor'
import 'vue-prism-editor/dist/prismeditor.min.css'

import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-bash'
import 'prismjs/themes/prism-tomorrow.css'

export default {
  components: {
    BCardActions,
    BFormGroup,
    BFormInput,
    BCardText,
    BRow,
    BCol,
    BButton,
    BModal,
    BFormSelect,
    PrismEditor,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
    settings: {
      type: Object,
      default: null,
    },
    billing: {
      type: Object,
      default: null,
    },
    script: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      domain: '',
      DefaultIPv4: '',
      DefaultIPv6: '',
      siteOptions: [
        { value: 'realdefault', text: 'realdefault.com' },
        { value: 'usdefault', text: 'usdefault.com' },
        { value: 'sbgdefault', text: 'sbgdefault.com' },
      ],
      formData: {
        defaultSite: '',
        dbPass: '',
        IPv4: '',
        IPv6: '',
        appSecret: '',
        appPrefix: '',
        networkName: '',
      },
      code: '',
    }
  },
  computed: {
    getPackage() {
      return uid => this.$store.getters['auth/getPackageByUID'](uid)
    },
  },
  created() {
    if (this.script) {
      this.formData.defaultSite = this.script.default_site
      this.formData.dbPass = this.script.db_password
      this.formData.IPv4 = this.script.ipv4
      this.formData.IPv6 = this.script.ipv6
      this.formData.appSecret = this.script.app_secret
      this.formData.appPrefix = this.script.app_prefix
      this.formData.networkName = this.script.network_name
    }
  },
  methods: {
    highlighter(code) {
      return highlight(code, languages.bash)
    },
    getAdminDomain() {
      if (this.settings.network_type === 'domain') return `${this.settings.sub_admin}.${this.settings.domain}`
      return `${this.settings.subdomain}.trafficmanager.com`
    },
    saveScriptSettings() {
      const payload = {
        user_id: this.user.user_id,
        'default-site': this.formData.defaultSite,
        ipv4: this.formData.IPv4,
        ipv6: this.formData.IPv6,
        'network-name': this.formData.networkName,
        'db-password': this.formData.dbPass,
        'app-secret': this.formData.appSecret,
        'app-prefix': this.formData.appPrefix,
      }

      this.$store.dispatch('users/saveScriptSettings', payload)
        .then(() => {
          this.generateScript()
          this.notify({
            text: 'Script has been generated successfully!',
            variant: 'success',
          })
        })
    },
    encryptPassword(password) {
      const salt = bcrypt.genSaltSync(6)
      return bcrypt.hashSync(password, salt)
    },
    generateScript() {
      this.settings.domain = this.settings.domain ? this.settings.domain : ''
      if (this.formData.defaultSite === 'realdefault') {
        this.DefaultIPv4 = '92.222.98.226'
        this.DefaultIPv6 = '2001:41d0:303:f23a::226'
      } else if (this.formData.defaultSite === 'usdefault') {
        this.DefaultIPv4 = '51.81.131.0'
        this.DefaultIPv6 = '2604:2dc0:0200:134e::0'
      } else if (this.formData.defaultSite === 'sbgdefault') {
        this.DefaultIPv4 = '176.31.129.224'
        this.DefaultIPv6 = '2001:41d0:0403:2e5a::224'
      }

      this.code = 'cd /etc/nginx/sites-available/\n'
      this.code += `cp -r ${this.formData.defaultSite} ${this.formData.networkName}\n`
      this.code += `cd ${this.formData.networkName}\n`

      if (this.settings.network_type === 'domain') {
        this.code += `find . -type f -print0 | xargs -0 sed -i 's/${this.formData.defaultSite}\\.com/${this.settings.domain}/g'\n`
        this.code += `find . -type f -print0 | xargs -0 sed -i 's/${this.formData.defaultSite}/${this.formData.networkName}/g'\n`
        this.domain = this.settings.domain
      } else if (this.settings.network_type === 'subdomain') {
        this.code += `sed -i 's/admin.${this.formData.defaultSite}.com/${this.settings.subdomain}.trafficmanager.com/g' admin\n`
        this.code += `sed -i 's/offers.${this.formData.defaultSite}.com/offers.${this.settings.subdomain}.trafficman.io/g' offers\n`
        this.code += `sed -i 's/smart.${this.formData.defaultSite}.com/smart.${this.settings.subdomain}.trafficman.io/g' smart\n`
        this.code += `sed -i 's/postback.${this.formData.defaultSite}.com/postback.${this.settings.subdomain}.trafficman.io/g' postback\n`
        this.code += `sed -i 's/banners.${this.formData.defaultSite}.com/banners-${this.settings.subdomain}.trafficman.io/g' banners\n`
        this.code += `sed -i 's/static.${this.formData.defaultSite}.com/static-${this.settings.subdomain}.trafficman.io/g' static\n`
        this.code += `find . -type f -print0 | xargs -0 sed -i 's/${this.formData.defaultSite}/${this.formData.networkName}/g'\n`
        this.domain = this.settings.subdomain
      }

      if (this.settings.network_type === 'domain') {
        if (this.settings.sub_offers !== 'offers') this.code += `sed -i 's/offers.${this.settings.domain}/${this.settings.sub_offers}.${this.settings.domain}/g' offers\n`
        if (this.settings.sub_smart !== 'smart') this.code += `sed -i 's/smart.${this.settings.domain}/${this.settings.sub_smart}.${this.settings.domain}/g' smart\n`
        if (this.settings.sub_admin !== 'admin') this.code += `sed -i 's/admin.${this.settings.domain}/${this.settings.sub_admin}.${this.settings.domain}/g' admin\n`
        if (this.settings.sub_banners !== 'banners') this.code += `sed -i 's/banners.${this.settings.domain}/${this.settings.sub_banners}.${this.settings.domain}/g' banners\n`
        if (this.settings.sub_static !== 'static') this.code += `sed -i 's/static.${this.settings.domain}/${this.settings.sub_static}.${this.settings.domain}/g' static\n`
        if (this.settings.sub_postback !== 'postback') this.code += `sed -i 's/postback.${this.settings.domain}/${this.settings.sub_postback}.${this.settings.domain}/g' postback\n`
      }

      this.code += `find . -type f -print0 | xargs -0 sed -i 's/${this.DefaultIPv4}/${this.formData.IPv4}/g'\n`
//      this.code += `find . -type f -print0 | xargs -0 sed -i 's/${this.DefaultIPv6}/${this.formData.IPv6}/g'\n\n`

      this.code += `mkdir /etc/nginx/sites-enabled/${this.formData.networkName}/\n`
      this.code += `ln -s /etc/nginx/sites-available/${this.formData.networkName}/* /etc/nginx/sites-enabled/${this.formData.networkName}/\n`
      this.code += `ll /etc/nginx/sites-enabled/${this.formData.networkName}/\n\n`
      this.code += `echo "include /etc/nginx/sites-enabled/${this.formData.networkName}/*;" | tee -a /etc/nginx/trafficmanager.conf\n\n`

      this.code += 'cd /etc/nginx/ssl\n'
      this.code += `cp ${this.formData.defaultSite}.conf ${this.formData.networkName}.conf\n\n`

      this.code += 'nginx -t # check if the configuration is successful (ok + successful), if it gives error the IP must be added, stop here\n'

      this.code += 'service nginx reload\n'
      this.code += '#Se vede site-ul?? (404 sau nu, daca nu incearca sa copiezi site-ul in var/www)\n'
      this.code += `#(if you open smart.${this.domain} without https it must give 404, if you open in https it will give: NET::ERR_CERT_COMMON_NAME_INVALID)\n\n`

      this.code += 'cd /var/www\n'
      this.code += `cp -ar ${this.formData.defaultSite} ${this.formData.networkName}\n\n`

      if (this.settings.network_type === 'domain') {
        this.code += 'certbot certonly --webroot -w \\\n'
        this.code += `/var/www/${this.formData.networkName}/trafficmanager/delivery/offers/ -d ${this.settings.sub_offers}.${this.domain} -w \\\n`
        this.code += `/var/www/${this.formData.networkName}/trafficmanager/delivery/ -d ${this.settings.sub_smart}.${this.domain} -w \\\n`
        this.code += `/var/www/${this.formData.networkName}/trafficmanager/content/banners/ -d ${this.settings.sub_banners}.${this.domain}  -w \\\n`
        this.code += `/var/www/${this.formData.networkName}/trafficmanager/public -d ${this.settings.sub_admin}.${this.domain} -w \\\n`
        this.code += `/var/www/${this.formData.networkName}/trafficmanager/public/themes/mt/ -d ${this.settings.sub_static}.${this.domain} -w \\\n`
        this.code += `/var/www/${this.formData.networkName}/trafficmanager/scripts/postback/ -d ${this.settings.sub_postback}.${this.domain}\n\n`

        this.code += `sed -i 's/${this.formData.defaultSite}\\.com/${this.domain}/g' /etc/nginx/ssl/${this.formData.networkName}.conf\n\n`
      } else {
        this.code += 'certbot certonly --webroot -w \\\n'
        this.code += `/var/www/${this.formData.networkName}/trafficmanager/delivery/offers/ -d offers.${this.domain}.trafficman.io -w \\\n`
        this.code += `/var/www/${this.formData.networkName}/trafficmanager/delivery/ -d smart.${this.domain}.trafficman.io -w \\\n`
        this.code += `/var/www/${this.formData.networkName}/trafficmanager/content/banners/ -d banners-${this.domain}.trafficman.io  -w \\\n`
        this.code += `/var/www/${this.formData.networkName}/trafficmanager/public -d ${this.domain}.trafficmanager.com -w \\\n`
        this.code += `/var/www/${this.formData.networkName}/trafficmanager/public/themes/mt/ -d static-${this.domain}.trafficman.io -w \\\n`
        this.code += `/var/www/${this.formData.networkName}/trafficmanager/scripts/postback/ -d postback.${this.domain}.trafficman.io\n\n`

        this.code += `sed -i 's/${this.formData.defaultSite}\\.com/${this.domain}.trafficman.io/g' /etc/nginx/ssl/${this.formData.networkName}.conf\n\n`
      }

      this.code += 'nginx -t\n'
      this.code += 'service nginx reload\n\n'

      this.code += `cd /var/www/${this.formData.networkName}/trafficmanager/\n`

      if (this.settings.network_type === 'domain') {
        this.code += `sed -i 's/${this.formData.defaultSite}.com/${this.domain}/g' scripts/config.php\n`
        this.code += `sed -i 's/${this.formData.defaultSite}.com/${this.domain}/g' config/parameters.yaml\n`
        this.code += `sed -i 's/${this.formData.defaultSite}/${this.formData.networkName}/g' scripts/config.php\n`
        this.code += `sed -i 's/${this.formData.defaultSite}/${this.formData.networkName}/g' config/parameters.yaml\n`
      } else {
        this.code += `sed -i 's/admin.${this.formData.defaultSite}.com/${this.settings.subdomain}.trafficmanager.com/g' scripts/config.php\n`
        this.code += `sed -i 's/smart.${this.formData.defaultSite}.com/${this.settings.sub_smart}.${this.settings.subdomain}.trafficman.io/g' scripts/config.php\n`
        this.code += `sed -i 's/offers.${this.formData.defaultSite}.com/${this.settings.sub_offers}.${this.settings.subdomain}.trafficman.io/g' scripts/config.php\n`
        this.code += `sed -i 's/banners.${this.formData.defaultSite}.com/${this.settings.sub_banners}-${this.settings.subdomain}.trafficman.io/g' scripts/config.php\n`
        this.code += `sed -i 's/static.${this.formData.defaultSite}.com/${this.settings.sub_static}-${this.settings.subdomain}.trafficman.io/g' scripts/config.php\n`
        this.code += `sed -i 's/${this.formData.defaultSite}.com/${this.settings.subdomain}.trafficmanager.com/g' scripts/config.php\n`
        this.code += `sed -i 's/${this.formData.defaultSite}/${this.formData.networkName}/g' scripts/config.php\n`
        this.code += `sed -i 's/static.${this.formData.defaultSite}.com/static-${this.settings.subdomain}.trafficman.io/g' config/parameters.yaml\n`
        this.code += `sed -i 's/${this.formData.defaultSite}/${this.formData.networkName}/g' config/parameters.yaml\n`
      }

      const dbPass = this.formData.dbPass.replaceAll('\\', '\\\\').replaceAll('!', '\\!').replaceAll('&', '\\&').replaceAll('$', '\\$')
      this.code += `sed -i 's/UQ+wtCN7Xa\\^mY\\^m8/${dbPass}/g' scripts/config.php\n`
      this.code += `sed -i 's/UQ+wtCN7Xa\\^mY\\^m8/${dbPass}/g' config/parameters.yaml\n`
      this.code += `sed -i 's/3JT9VTDyRDJ3kVBxwSuv8gqwDLntTt3yacQ9X/${this.formData.appSecret}/g' scripts/config.php\n`
      this.code += `sed -i 's/3JT9VTDyRDJ3kVBxwSuv8gqwDLntTt3yacQ9X/${this.formData.appSecret}/g' config/parameters.yaml\n`
      this.code += `sed -i "s/'RD'/'${this.formData.appPrefix}'/g" scripts/config.php\n`
      this.code += `sed -i 's/app_prefix: RD/app_prefix: ${this.formData.appPrefix}/g' config/parameters.yaml\n`
      this.code += `sed -i "s/'${this.DefaultIPv4}'/'${this.formData.IPv4}'/g" scripts/config.php\n`

      this.code += 'vi /var/www/update_all.sh\n\n'

      this.code += `mysql -e "CREATE SCHEMA ${this.formData.networkName};"\n`
      this.code += `mysql -e "CREATE USER '${this.formData.networkName}'@'localhost' IDENTIFIED WITH 'caching_sha2_password' BY '${dbPass}';"\n`
      this.code += `mysql -e "ALTER USER '${this.formData.networkName}'@'localhost' WITH MAX_QUERIES_PER_HOUR 0 MAX_CONNECTIONS_PER_HOUR 0 MAX_UPDATES_PER_HOUR 0 MAX_USER_CONNECTIONS 0;"\n`
      this.code += `mysql -e "GRANT SELECT, INSERT, UPDATE, DELETE, CREATE, DROP, REFERENCES, INDEX, ALTER, CREATE TEMPORARY TABLES, LOCK TABLES, CREATE VIEW, SHOW VIEW, CREATE ROUTINE, ALTER ROUTINE, EXECUTE, EVENT, TRIGGER ON ${this.formData.networkName}.* TO '${this.formData.networkName}'@'localhost';"\n`
      this.code += `mysql -e "GRANT SELECT, INSERT, UPDATE, DELETE, CREATE, DROP, REFERENCES, INDEX, ALTER, CREATE TEMPORARY TABLES, LOCK TABLES, CREATE VIEW, SHOW VIEW, CREATE ROUTINE, ALTER ROUTINE, EXECUTE, EVENT, TRIGGER ON geoip.* TO '${this.formData.networkName}'@'localhost';"\n`
      this.code += `mysql -e "GRANT SELECT, INSERT, UPDATE, DELETE, CREATE, DROP, REFERENCES, INDEX, ALTER, CREATE TEMPORARY TABLES, LOCK TABLES, CREATE VIEW, SHOW VIEW, CREATE ROUTINE, ALTER ROUTINE, EXECUTE, EVENT, TRIGGER ON common.* TO '${this.formData.networkName}'@'localhost';"\n\n`

      this.code += `mysqldump ${this.formData.defaultSite} > ${this.formData.defaultSite}.sql\n`
      this.code += `cat ${this.formData.defaultSite}.sql | mysql ${this.formData.networkName}\n`
      this.code += `rm ${this.formData.defaultSite}.sql\n`
      this.code += 'su www-data\n'
      this.code += 'git pull && ../../deploy_script.sh\n'
      this.code += `#in /var/www/${this.formData.networkName}/trafficmanager/ change the password to ${this.settings.admin_password ? this.settings.admin_password : 'test'}:\n`

      let setting_password = this.settings.admin_password_test
      if (this.settings.admin_password_encryp) setting_password = this.settings.admin_password_encryp

      setting_password = setting_password.replaceAll('\\', '\\\\')
      setting_password = setting_password.replaceAll('!', '\\!')
      setting_password = setting_password.replaceAll('&', '\\&')
      setting_password = setting_password.replaceAll('$', '\\$')

      this.code += `php bin/console doctrine:query:sql "UPDATE users SET password='${setting_password}' WHERE id=2;"\n`
      this.code += `php bin/console doctrine:query:sql "UPDATE users SET email='${this.user.email}' WHERE id=2;"\n`
      this.code += `php bin/console doctrine:query:sql "UPDATE domains SET domain='${this.settings.sub_smart}.${this.domain}${this.settings.network_type === 'subdomain' ? '.trafficman.io' : ''}' WHERE id=1;"\n`
      this.code += `curl -4 --interface 127.0.0.1 https://${this.getAdminDomain()}/api/admin/tmSetOption/tm_plan/${this.getPackage(this.billing.package).legacy === 0 ? `${this.getPackage(this.billing.package).name.toLowerCase()}_2` : this.getPackage(this.billing.package).name.toLowerCase()}\n`

      if (this.settings.dns_type === 'tmdns') this.code += `curl -4 --interface 127.0.0.1 https://${this.getAdminDomain()}/api/admin/tmSetOption/tm_tmdns/1\n`
      else this.code += `curl -4 --interface 127.0.0.1 https://${this.getAdminDomain()}/api/admin/tmSetOption/tm_tmdns/0\n`

      this.code += `curl -4 --interface 127.0.0.1 https://${this.getAdminDomain()}/api/admin/tmSetOption/tm_network_name/${this.formData.networkName}\n`

      if (this.settings.network_type === 'domain') {
        this.code += `curl -4 --interface 127.0.0.1 https://${this.getAdminDomain()}/api/admin/tmSetOption/params.domain/${this.domain}\n`
        this.code += `curl -4 --interface 127.0.0.1 https://${this.getAdminDomain()}/api/admin/tmSetOption/domains.admin/${this.settings.sub_admin}.${this.domain}\n`
        this.code += `curl -4 --interface 127.0.0.1 https://${this.getAdminDomain()}/api/admin/tmSetOption/domains.banners/${this.settings.sub_banners}.${this.domain}\n`
        this.code += `curl -4 --interface 127.0.0.1 https://${this.getAdminDomain()}/api/admin/tmSetOption/domains.offers/${this.settings.sub_offers}.${this.domain}\n`
        this.code += `curl -4 --interface 127.0.0.1 https://${this.getAdminDomain()}/api/admin/tmSetOption/domains.postback/${this.settings.sub_postback}.${this.domain}\n`
        this.code += `curl -4 --interface 127.0.0.1 https://${this.getAdminDomain()}/api/admin/tmSetOption/domains.static/${this.settings.sub_static}.${this.domain}\n\n`
      } else {
        this.code += `curl -4 --interface 127.0.0.1 https://${this.getAdminDomain()}/api/admin/tmSetOption/params.domain/${this.domain}.trafficman.io\n`
        this.code += `curl -4 --interface 127.0.0.1 https://${this.getAdminDomain()}/api/admin/tmSetOption/domains.admin/${this.domain}.trafficman.io\n`
        this.code += `curl -4 --interface 127.0.0.1 https://${this.getAdminDomain()}/api/admin/tmSetOption/domains.banners/${this.settings.sub_banners}-${this.domain}.trafficman.io\n`
        this.code += `curl -4 --interface 127.0.0.1 https://${this.getAdminDomain()}/api/admin/tmSetOption/domains.offers/${this.settings.sub_offers}.${this.domain}.trafficman.io\n`
        this.code += `curl -4 --interface 127.0.0.1 https://${this.getAdminDomain()}/api/admin/tmSetOption/domains.postback/${this.settings.sub_postback}.${this.domain}.trafficman.io\n`
        this.code += `curl -4 --interface 127.0.0.1 https://${this.getAdminDomain()}/api/admin/tmSetOption/domains.static/${this.settings.sub_static}-${this.domain}.trafficman.io\n\n`
      }

      this.code += '## Recaptcha\n'
      this.code += '# If first account is used, add here:\n'
      this.code += '# https://www.google.com/recaptcha/admin/site/338084833/settings\n'
      this.code += '# If second account:\n'
      this.code += '# https://www.google.com/recaptcha/admin/site/614770408/settings\n'
      this.code += `add ${this.domain}\n`
      this.code += '# If the second account is used, add the key in .env.prod:\n'
      this.code += 'echo \'# Second account: https://www.google.com/recaptcha/admin/site/614770408/settings\' >> .env.prod\n'
      this.code += 'echo \'RECAPTCHA_PUBLIC=6LfopqQkAAAAAOuoGv5XEQf8hbNMXnusO5R7aGBm\' >> .env.prod\n'
      this.code += 'echo \'RECAPTCHA_PRIVATE=6LfopqQkAAAAAEnlBlSudF9XHm2GE2m2wE_3plnB\' >> .env.prod\n'
      this.code += ' # Check that the file contains the 3 lines, only once\n'
      this.code += 'cat .env.prod\n\n'

      this.code += 'exit\n\n'

      this.code += 'cd /etc/php/8.3/fpm/pool.d/\n'
      this.code += `cp /home/francescom/pools/8.3/${this.getPackage(this.billing.package).name.toLowerCase()}.conf ${this.formData.networkName}.conf\n`
      this.code += `sed -i 's/realdefault/${this.formData.networkName}/g' ${this.formData.networkName}.conf\n`
      this.code += 'service php8.3-fpm reload\n'
      this.code += `#Check that https://smart.${this.domain}/asdfgfhjkl gives 404\n`
      this.code += `mkdir /var/www/logs/${this.formData.networkName} # create folder\n`
      this.code += `chown -R www-data:www-data /var/www/logs/${this.formData.networkName}/\n`
      this.code += 'vi /etc/crontab # add at the end the new platform\n'

      this.code += `* *     * * *   www-data    php /var/www/${this.formData.networkName}/trafficmanager/scripts/jobs.php >> /var/www/logs/${this.formData.networkName}/jobs.log\n\n`

      this.code += `# Create a mail (info@${this.domain}) - user normal\n`
      this.code += '# Add the mail userSettings in Site.com/admin\n'
      this.code += '#+ catchALL alias\n\n'

      this.code += '# Supervisor:\n'
      this.code += `cp /home/francescom/pools/supervisor.conf /etc/supervisor/conf.d/${this.formData.networkName}.conf\n`
      this.code += `sed -i 's/realdefault/${this.formData.networkName}/g' /etc/supervisor/conf.d/${this.formData.networkName}.conf\n`
      this.code += 'service supervisor reload\n'
      this.code += '# Check that the new messenger:consume is running:\n'
      this.code += 'service supervisor status\n\n'

      this.code += '# Log in on the platform as TM_TECH, then:\n'
      this.code += `https://${this.getAdminDomain()}/en/admin/tech/reset-uuid\n`
      this.code += '# Go here and press confirm\n\n'

      this.code += '# test:\n'
      this.code += '# ADMIN: New campaign https://www.example.com/?clickid={clickid}, upload banners\n'
      this.code += '# test banner, make 1 click, make a conversion with the postback by replacing the clickid from the click\n'
      this.code += '# ADMIN: New offer\n'
      this.code += '# Landing pages: https://www.example.com/?clickid={clickid}\n'
      this.code += '# Add banner\n\n'

      this.code += '# Creare user.\n'
      this.code += '# If RTB is enabled: Add traffic sources, \n'
      this.code += '# Promotion tools, took the code to test: https://www.w3schools.com/html/tryit.asp?filename=tryhtml_basic\n\n'

      this.code += '# USER: Offers userSettings -> Global postback https://example.com/?payout={payout}&clickid={clickid}\n'
      this.code += '# USER: marketplace -> Banner rotator (open the banner iframe in a new tab and click on the banner)\n'
      this.code += '# ADMIN: Marketplace -> Edit offer-> Postback: TEST\n\n'

      this.code += '# Run reset db script: \n'
      this.code += `https://${this.getAdminDomain()}/en/admin/tech/reset-db\n`
      this.code += '# Add the row in trafficmanager.installations\n\n'

      this.code += '# Ready message:\n\n'

      this.code += 'Your TrafficManager platform is ready!\n\n'

      this.code += 'Dear partner,\n\n'

      this.code += 'We are happy to inform you that your platform is ready to use.\n\n'

      this.code += `Login URL: https://${this.getAdminDomain()}/\n`
      this.code += 'Username: admin\n'
      this.code += 'Password: your chosen password\n\n'

      this.code += `To begin, you can configure most of your platform settings here: https://${this.getAdminDomain()}/en/admin/settings\n\n`
      if (this.settings.dns_type === 'tmdns') {
        this.code += `We've created an email account info@${this.domain} with password: PASSWORD\n`
        this.code += 'You can log in to the webmail here: https://mail.trafficmanager.com/\n\n'

        this.code += 'Here you can find how to configure your email clients: https://www.trafficmanager.com/blog/2019/06/trafficmanager-email-instructions/\n'
        this.code += 'If you change the password, remember to change it also in the settings; otherwise, the system will not be able to send emails to your users anymore. If you need other mailboxes, just let us know.\n\n'
      }

      this.code += 'Here you can find many guides on how to use the platform: https://www.trafficmanager.com/guides/\n\n'

      this.code += 'For any further assistance please do not hesitate to contact us!\n\n'
      this.code += 'Best regards,\n'
      this.code += 'TrafficManager Team\n'

      this.$bvModal.show('install-script-modal')
    },
  },
}
</script>

<style lang="scss">
.dark-layout pre {
  background-color: unset !important;
}

#install-script-modal {
  .modal-body {
    padding: 2px 9px !important;
  }

  .modal-dialog {
    max-width: 986px !important;
  }

  .my-editor {
    background: #2d2d2d;
    color: #ccc;

    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 14px;
    line-height: 1.5;
    padding: 5px;
  }
  .my-editor pre {
    color: #ccc;
  }
  .prism-editor__textarea:focus {
    outline: none;
  }
}

</style>
